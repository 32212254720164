import * as React from 'react'
import {useEffect, useState} from 'react';
import { Card, Button, Table } from 'react-bootstrap'
import { MemberType, UserInfo } from '../../contexts/ContextTypes';
import {ReactComponent as Add} from "../../imgs/add.svg"
import { useAdminContext } from '../../contexts/AdminContext';

export default function UnapprovedUserList() {
  const { getUnapprovedUsersList, approveUser } = useAdminContext(); 
  const [unapprovedUserRows, setUnapprovedUserRows] = useState<Array<JSX.Element>>([]);
  const [updateSub, updatePub] = useState<number>(0);
  useEffect(() => {
    getUnapprovedUsersList().then((res) => {
      setUnapprovedUserRows(res.map(availableDateToTableRow));
    });

    function availableDateToTableRow(unapprovedUser : UserInfo) : JSX.Element {
      return (
        <tr key={unapprovedUser.uid}>
          <td className='text-center'>
            <>{`${unapprovedUser.firstName} ${unapprovedUser.lastName}`}</>
            <>{unapprovedUser.memberType === MemberType.trial && <span className="badge bg-warning text-dark ms-2">TRIAL</span>}</>
          </td>
          <td className='text-center'>{unapprovedUser.email}</td>
          <td className='text-center'>{unapprovedUser.dateCreated.toDateString().substring(4)}</td>
          <td className='text-center'>
            <Button variant='success' className='d-inline-flex' onClick={() => {
              approveUser(unapprovedUser.uid);
              updatePub(updateSub + 1);
            }}>
              <Add className='bi bi-trash' height="15" width="15"/>
            </Button>
          </td>
        </tr>
      );
    }
  }, [getUnapprovedUsersList, approveUser, updateSub]);
  
  return (
    <Card>
      <Card.Header>
        <h2 className='text-center mb-4'>Unapproved Users</h2>
      </Card.Header>
      <Card.Body>
        <Table>
            <thead>
              <tr>
                <th className='text-center'>Name</th>
                <th className='text-center'>Email</th>
                <th className='text-center'>Sign Up Date</th>
                <th className='text-center'>Approve</th>
              </tr>
            </thead>
            <tbody>
              {unapprovedUserRows}
            </tbody>
          </Table>
      </Card.Body>
    </Card>
  )
}
