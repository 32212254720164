import { QueryDocumentSnapshot, SnapshotOptions, Timestamp } from "firebase/firestore";
import { SelectableUser } from "../contexts/ContextTypes";

type firestoreFlightInfoType = {
  id?: string,
  date: Date,
  length: number,
  compNumber: string,
  launchType: string,
  launchFailure: string,
  launchLocation: string,
  p1: SelectableUser,
  p2?: SelectableUser,
  freeLaunch?: boolean,
}

export enum launchTypes {
  winch = "Winch",
  bungee = "Bungee",
  autotow = "Autotow",
  aerotow = "Aerotow",
  selfLaunch = "Self Launch",
  touringMotorGlider = "Touring Motor Glider",
}

export enum launchFailureTypes {
  none = "None",
  tlf = "TLF",
  rlf = "RLF",
}

export class Flight {
  id?: string;
  date: Date;
  length: number;
  compNumber: string;
  launchType: launchTypes;
  launchFailure: launchFailureTypes;
  launchLocation: string;
  p1: SelectableUser;
  p2?: SelectableUser;
  freeLaunch: boolean;

  constructor (
    id: string | undefined,
    date: Date,
    length: number,
    compNumber: string,
    launchType: string,
    launchFailure: string,
    launchLocation: string,
    p1: SelectableUser,
    p2: SelectableUser | undefined,
    freeLaunch: boolean = false,
    ) {

    this.id = id;
    this.date = date;
    this.length = length;
    this.compNumber = compNumber;

    switch (launchType.toLowerCase()) {
      case "winch":
        this.launchType = launchTypes["winch"]; break;
      case "bungee":
        this.launchType = launchTypes["bungee"]; break;
      case "autotow":
        this.launchType = launchTypes["autotow"]; break;
      case "aerotow":
        this.launchType = launchTypes["aerotow"]; break;
      case "selfLaunch":
        this.launchType = launchTypes["selfLaunch"]; break;
      case "touringMotorGlider":
        this.launchType = launchTypes["touringMotorGlider"]; break;
      default:
        this.launchType = launchTypes["winch"]; break;
    }

    const failureType = launchFailure?.toLowerCase()
    switch (failureType) {
      case undefined: this.launchFailure = launchFailureTypes["none"]; break;
      case "none": this.launchFailure = launchFailureTypes["none"]; break;
      case "tlf": this.launchFailure = launchFailureTypes["tlf"]; break;
      case "rlf": this.launchFailure = launchFailureTypes["rlf"]; break;
      default: this.launchFailure = launchFailureTypes["none"]; break;
    }

    this.launchLocation = launchLocation;
    this.p1 = p1;
    this.p2 = p2;
    this.freeLaunch = freeLaunch;
  }

  toString() {
    return this.date + ': ' + this.p1 + ((this.p2) ? " and" + this.p2 : "") + " in " + this.compNumber;
  }
}

// Firestore data converter
export const flightConverter = {
  toFirestore: (flight: Flight) => {
    let data: firestoreFlightInfoType = {
      date: flight.date,
      length: flight.length,
      compNumber: flight.compNumber,
      launchType: flight.launchType,
      launchFailure: flight.launchFailure.valueOf(),
      launchLocation: flight.launchLocation,
      p1: flight.p1,
      freeLaunch: flight.freeLaunch,
    }

    if (flight.p2 !== undefined)
      data.p2 = flight.p2;
    
    if (flight.id !== undefined)
      data.id = flight.id;
    
    return data;
  },
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions) => {
      const data = snapshot.data(options);
      return new Flight(
        snapshot.id,
        new Timestamp(data.date.seconds, data.date.nanoseconds).toDate(),
        data.length,
        data.compNumber,
        data.launchType,
        data.launchFailure,
        data.launchLocation,
        data.p1,
        data.p2,
        data.freeLaunch,
      );
  }
};