import * as React from "react";
import { ColumnDef, ColumnHelper, Row, createColumnHelper } from "@tanstack/react-table";
import { Status, UserFlyingDay } from "../models/UserFlyingDay";
import { Flight, launchFailureTypes } from "../models/Flight";
import { CostCalculator } from "../models/CostCalculator";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import numberToMoney from "../utils/numberToMoney";

export class FlightsColumnBuilder {
  columnHelper: ColumnHelper<UserFlyingDay | Flight>;
  columns: ColumnDef<UserFlyingDay | Flight, any>[];

  constructor() {
    this.columnHelper = createColumnHelper<UserFlyingDay | Flight>();
    this.columns = [];
  }

  addDate(width: number) {
    this.columns.push(
      this.columnHelper.accessor("date", {
        header: ({ table }) => (
          <>
            <IndeterminateCheckbox
              {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler(),
              }}
            />
            <> Date</>
          </>
        ),

        cell: (info) => (
          <div className="px-1" style={{ paddingLeft: `${info.row.depth * 2}rem` }}>
            <ExpandingButton row={info.row}></ExpandingButton>
            {(info.row.original instanceof UserFlyingDay) ? <IndeterminateCheckbox
              {...{
                checked: info.row.getIsSelected(),
                disabled: !info.row.getCanSelect(),
                indeterminate: info.row.getIsSomeSelected(),
                onChange: info.row.getToggleSelectedHandler(),
              }}
            /> : <></>}
            <>
              {" " + 
              info
                .getValue()
                .toDateString()
                .substring((width < 500) ? 4 : 0)
              }
            </>
          </div>
        )
      }));
  }

  addP1P2() {
    this.columns.push(
      this.columnHelper.accessor(row => (row instanceof UserFlyingDay) ? row.user : row.p2, {
        header: "P1/ P2",
        cell: info => (info.row.original instanceof UserFlyingDay)
          ?
          `${info.row.original.user.firstName} ${info.row.original.user.lastName}`
          :
          (info.row.original.p2 === undefined) ? "Solo" : `${info.row.original.p2.firstName} ${info.row.original.p2.lastName}`
      })
    );
  }

  addLaunches() {
    this.columns.push(
      this.columnHelper.accessor(row => (row instanceof UserFlyingDay) ? row.launches : row.launchType, {
        header: "Launches",
        cell: info => (info.row.original instanceof UserFlyingDay)
          ?
          info.getValue()
          :
          `${info.getValue()} ${(info.row.original.launchFailure === launchFailureTypes.none)
            ?
            ""
            :
            info.row.original.launchFailure.toString()
          }`
      })
    );
  }

  addFlightTime() {
    this.columns.push(
      this.columnHelper.accessor(row => (row instanceof UserFlyingDay) ? row.totalMins : row.length, {
        header: "Total Flight Time",
        cell: info => info.getValue()
      })
    );
  }

  addCost() {
    this.columns.push(
      this.columnHelper.accessor(row =>
        (row instanceof UserFlyingDay) ?
          row.cost :
          CostCalculator.approximateFlightCost(row).cost
        , {
          header: "Cost",
          cell: info => <>£{numberToMoney(info.getValue())}{(info.row.original instanceof Flight) ? "~" : ""}</>
        })
    );
  }

  addStatus() {
    const statusMSG = (status: Status | null) => {
      switch (status) {
        case (Status.Unpaid):
          return "Day has not been paid";
        case (Status.ClaimedPaid):
          return "Day payment is awaiting admin approval";
        case (Status.Paid):
          return "Day has been paid";
        default:
          return "Flight";
      }
    }

    const statusIcon = (status: Status | null) => {
      switch (status) {
        case (Status.Unpaid):
          return "🔴";
        case (Status.ClaimedPaid):
          return "🟡";
        case (Status.Paid):
          return "🟢";
        default:
          return "✈️"
      }
    }

    this.columns.push(
      this.columnHelper.accessor(row =>
        (row instanceof UserFlyingDay) ?
          row.status :
          null
        , {
          header: "Status",
          cell: info =>
            <OverlayTrigger overlay={
              <Tooltip>
                {statusMSG(info.getValue())}
              </Tooltip>
            }>
              <button
                style={{
                  cursor: "pointer",
                  border: "none",
                  backgroundColor: "transparent"
                }}>
                {statusIcon(info.getValue())}
              </button>
            </OverlayTrigger>
        })
    )
  }

  getColumns() {
    return this.columns
  }
}

type ExpanderType = {
  row: Row<UserFlyingDay | Flight>
}

function ExpandingButton(props: ExpanderType) {
  const { row } = props;
  return (
    (row.getCanExpand()) ? (
      <button
        {...{
          onClick: () =>
            row.toggleExpanded()
          ,
          style: {
            cursor: "pointer",
            border: "none",
            backgroundColor: "transparent"
          },
        }}
      >
        {row.getIsExpanded() ? "▽" : "▷"}
      </button>
    ) : (
      <span {...{
        style: { border: "none", paddingRight: "3rem" },
      }}
      >
        {""}
      </span>
    )
  )
}

function IndeterminateCheckbox({
  indeterminate,
  className = '',
  ...rest
}: { indeterminate?: boolean } & React.HTMLProps<HTMLInputElement>) {
  const ref = React.useRef<HTMLInputElement>(null!)

  React.useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate
    }
  }, [ref, indeterminate, rest.checked])

  return (
    <input
      type="checkbox"
      ref={ref}
      className={className + ' cursor-pointer'}
      {...rest}
    />
  )
}
