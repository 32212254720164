import {
  getDocs,
  CollectionReference,
  Timestamp,
  collection,
  setDoc,
  doc
} from "firebase/firestore";
import { UserInfo } from "./ContextTypes";
import { UserFlyingDay, userFlyingDayConverter } from "../models/UserFlyingDay";
import { flightConverter } from "../models/Flight";

type TimestampType = {
  seconds: number;
  nanoseconds: number;
};

export const timestampToDate = (timestamp: TimestampType) =>
  timestamp
    ? new Timestamp(timestamp.seconds, timestamp.nanoseconds).toDate()
    : undefined;

export async function getUserList(collection: CollectionReference) {
  const availabilitySnap = await getDocs(collection);

  let list: Array<UserInfo> = [];
  //TODO: Remake this with returns map().sort()
  availabilitySnap.forEach((user) => {
    const dateCreated = timestampToDate(user.get("dateCreated"));
    if (!dateCreated) return;

    const dateRequested = timestampToDate(user.get("dateRequested"));

    let dob = timestampToDate(user.get("dob"));
    if (!dob) {
      dob = new Date()
      dob.setFullYear(dob.getFullYear() - 50);
    }

    const lastFlew = timestampToDate(user.get("lastFlew"));

    list.push({
      uid: user.id,
      firstName: user.get("firstName"),
      lastName: user.get("lastName"),
      email: user.get("email"),
      dateCreated: dateCreated,
      dateRequested: dateRequested,
      dob: dob,
      isDriver: user.get("isDriver"),
      daysDriver: user.get("daysDriver"),
      passengerCount: user.get("passengerCount"),
      final: user.get("final"),
      owes: user.get("owes"),
      lastFlew: lastFlew,
      memberType: user.get("memberType"),
    });
  });

  list.sort((a, b) => a.dateCreated.getTime() - b.dateCreated.getTime());
  return list;
}


export async function getFlights(userFlyingDay: UserFlyingDay, collectionRef: CollectionReference) {
  const flights = await getDocs(
    collection(
      collectionRef,
      userFlyingDay.getDayReference(),
      "flights"
    ).withConverter(flightConverter));

  userFlyingDay.setFlights(
    flights.docs.map(
      (flightSnapshot) => flightSnapshot.data()
    )
  );
  return userFlyingDay;
}

//TODO: ReWrite with batch?
export async function addFlyingDayToCollection(flyingDay: UserFlyingDay, collection: CollectionReference) {
  await Promise.all([
    setDoc(
      doc(
        collection,
        flyingDay.getDayReference()
      )
        .withConverter(userFlyingDayConverter),
      flyingDay
    ),
    flyingDay.flights.flatMap((flight) =>
      setDoc(
        doc(
          collection,
          flyingDay.getDayReference(),
          "flights",
          flight.id ? flight.id : Math.random().toString(16).slice(2)
        )
          .withConverter(flightConverter),
        flight
      )
    ),
  ])
}
