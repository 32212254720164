import * as React from 'react';
import { useState } from "react";
import { Button, Card, Form, Modal } from 'react-bootstrap';
import {
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
  getExpandedRowModel,
  ExpandedState,
} from '@tanstack/react-table'
import { UserFlyingDay } from '../../models/UserFlyingDay';
import BootstrapTable from '../../table/BootstrapTable';
import { FlightsColumnBuilder } from '../../table/FlightsColumnBuilder';
import { useAdminContext } from '../../contexts/AdminContext';
import { useDisplayedListsContext } from '../../contexts/DisplayedListsContext';
import {ReactComponent as Trash} from "../../imgs/trash.svg"

export default function UnpublishedFlights() {
  const { publishFlyingDays, deleteUnpublishedDay } = useAdminContext();
  const { unpublishedUserFlyingDays } = useDisplayedListsContext();
  const [ expanded, setExpanded ] = useState<ExpandedState>({})
  const [ showDeletionConfirmationModal, setShowDeletionConfirmationModal ] = useState(false)

  const columnBuilder = new FlightsColumnBuilder();
  columnBuilder.addDate(100000);
  columnBuilder.addP1P2();
  columnBuilder.addLaunches();
  columnBuilder.addFlightTime();
  columnBuilder.addCost();

  const table = useReactTable({
    data: unpublishedUserFlyingDays,
    columns: columnBuilder.getColumns(),
    state: {
      expanded,
    },
    getCoreRowModel: getCoreRowModel<UserFlyingDay>(),
    getPaginationRowModel: getPaginationRowModel(),
    enableMultiRowSelection: true,
    enableSubRowSelection: true,
    enableGrouping: true,
    getRowCanExpand: (row) => row.original instanceof UserFlyingDay,
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel<UserFlyingDay>(),
    getSubRows: row => (row instanceof UserFlyingDay) ? row.flights : []
  })

  function publishDays(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const flyingDays: Array<UserFlyingDay> = [];
    table.getSelectedRowModel().rows.forEach(row => {
      if (row.original instanceof UserFlyingDay)
        flyingDays.push(row.original)
    })

    publishFlyingDays(flyingDays);
  }

  function submitDeleteDaysForm(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setShowDeletionConfirmationModal(true);
  }
  
  function deleteSelectedDays() {
    table.getSelectedRowModel().rows.forEach(row => {
      if (row.original instanceof UserFlyingDay)
        deleteUnpublishedDay(row.original.date, row.original.user);
    });
  
    table.setRowSelection({});
    setShowDeletionConfirmationModal(false);
  }

  return (
    <Card style={{ minWidth: "44em" }}>
      <Card.Header>
        <h2 className='text-center mb-4'>Unpublished Flights</h2>
      </Card.Header>
      <Card.Body className="d-flex justify-content-center row">
        {/* https://react-bootstrap-table.github.io/react-bootstrap-table2/ */}
        <BootstrapTable table={table}>
          <div className='d-flex justify-content-between'>
            <Form onSubmit={submitDeleteDaysForm} className='me-4'>
              <Button
                variant='danger'
                type='submit'
                disabled={table.getSelectedRowModel().rows.length === 0}
                className="d-flex justify-content-center align-items-center h-100"
              >
                <Trash className='bi bi-trash'/>
              </Button>
            </Form>
            <Form onSubmit={publishDays} className='flex-grow-1'>
              <Button
                className='w-100'
                type='submit'
                disabled={table.getSelectedRowModel().rows.length === 0}
              >Publish ({table.getSelectedRowModel().rows.length}) selected Day(s)</Button>
            </Form>
          </div>
        </BootstrapTable>
        <Modal show={showDeletionConfirmationModal} onHide={() => setShowDeletionConfirmationModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Deletion</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete the selected days?
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={() => setShowDeletionConfirmationModal(false)}>Cancel</Button>
            <Button variant='danger' onClick={deleteSelectedDays}>Delete</Button>
          </Modal.Footer>
        </Modal>
      </Card.Body>
      <Card.Footer>

      </Card.Footer>
    </Card>
  )
}
