import * as React from "react";
import { useContext } from "react";
import { db } from "../firebase";
import {
  getDocs,
  collection,
  Timestamp,
  doc,
  getDoc,
  updateDoc,
  writeBatch,
  DocumentSnapshot,
  DocumentData,
  deleteDoc,
  setDoc,
  addDoc,
} from "firebase/firestore"
import { AdminContextModel } from "./AdminContextInterface";
import { ProviderProps, SelectableUser, UserInfo } from "./ContextTypes";
import { addFlyingDayToCollection, getFlights, getUserList } from "./Utils";
import { Payment, PaymentStatus } from "../models/Payment";
import { useDisplayedListsContext } from "./DisplayedListsContext";
import { Status, UserFlyingDay } from "../models/UserFlyingDay";
import { Flight, flightConverter } from "../models/Flight";
import { FlyingRate, flyingRateConverter } from "../models/FlyingRate";
import { CostCalculator } from "../models/CostCalculator";
import { Glider, gliderConverter } from "../models/Glider";
import { useAuth } from "./AuthContext";

const DBContext = React.createContext<AdminContextModel>({} as AdminContextModel);

export function useAdminContext() {
  return useContext(DBContext);
}

const userDocumentReference = (uid: string) => doc(db, "users", uid);

const unapprovedUsersListReference = () => collection(db, "unapprovedUsers");

const userAvailabilityReference = (date: Date, uid: string) =>
  doc(db, "users", uid, "availability", Timestamp.fromDate(date).valueOf());

const flyingListReference = (date: Date) =>
  collection(
    db,
    "availability",
    Timestamp.fromDate(date).valueOf(),
    "flyingList"
  );

const signedUpListReference = (date: Date) =>
  collection(
    db,
    "availability",
    Timestamp.fromDate(date).valueOf(),
    "signedUp"
  );

const flyingDayReference = (date: Date) =>
  doc(db, "availability", Timestamp.fromDate(date).valueOf());

export function AdminProvider({ children }: ProviderProps): JSX.Element {
  const { currentUser, currentUserInfo } = useAuth();
  const { updatePayments, unpublishedUserFlyingDays, updateUnpublishedUserFlyingDays } = useDisplayedListsContext();

  async function getFlyingListInfo(date: Date) {
    if (!currentUser) throw new Error("User is not signed in");
    if (!currentUserInfo) throw new Error("User is not in DB");
    if (!currentUserInfo.isAdmin) throw new Error("User is not an admin");

    var promises: Array<
      Promise<Array<UserInfo> | DocumentSnapshot<DocumentData>>
    > = [];

    promises.push(getUserList(signedUpListReference(date)));

    promises.push(getUserList(flyingListReference(date)));

    promises.push(getDoc(flyingDayReference(date)));

    const results = await Promise.all(promises);
    if (
      !(results[0] instanceof Array) ||
      !(results[1] instanceof Array) ||
      !(results[2] instanceof DocumentSnapshot)
    )
      throw new Error("Great programming resulted in an impossible state");

    return {
      availableUsers: results[0],
      currentFlyingList: results[1],
      final: results[2].get("final") ? true : false,
    };
  }

  async function addUserToFlyingList(userInfo: UserInfo, date: Date) {
    if (!currentUser) throw new Error("User is not signed in");
    if (!currentUserInfo) throw new Error("User is not in DB");
    if (!currentUserInfo.isAdmin) throw new Error("User is not an admin");

    delete userInfo.email;
    delete userInfo.final;
    delete userInfo.owes;
    delete userInfo.lastFlew;
    if (!userInfo.isDriver) {
      delete userInfo.isDriver;
      delete userInfo.daysDriver;
      delete userInfo.passengerCount;
    } else {
      userInfo.daysDriver = true;
    }
    if (!userInfo.memberType) {
      delete userInfo.memberType;
    }

    return await setDoc(doc(flyingListReference(date), userInfo.uid), userInfo);
  }

  async function toggleUserDriverStatus(userInfo: UserInfo, date: Date) {
    if (!currentUser) throw new Error("User is not signed in");
    if (!currentUserInfo) throw new Error("User is not in DB");
    if (!currentUserInfo.isAdmin) throw new Error("User is not an admin");

    await updateDoc(doc(flyingListReference(date), userInfo.uid), {
      daysDriver: !userInfo.daysDriver,
    })
  };

  async function removeUserFromFlyingList(userInfo: UserInfo, date: Date) {
    if (!currentUser) throw new Error("User is not signed in");
    if (!currentUserInfo) throw new Error("User is not in DB");
    if (!currentUserInfo.isAdmin) throw new Error("User is not an admin");

    await deleteDoc(doc(flyingListReference(date), userInfo.uid));
  }
  
  async function finalizeFlyingList(
    date: Date,
    leavingTime: number,
    notes: String
  ) {
    if (!currentUser) throw new Error("User is not signed in");
    if (!currentUserInfo) throw new Error("User is not in DB");
    if (!currentUserInfo.isAdmin) throw new Error("User is not an admin");

    const leavingDate = new Date(date);
    leavingDate.setHours(leavingTime);
    leavingDate.setMinutes(60 * (leavingTime - Math.floor(leavingTime)));

    const finalizeJobs = writeBatch(db);

    const flyingUsers = await getDocs(signedUpListReference(date));
    let driverReference: string | undefined = undefined;

    flyingUsers.forEach((user) => {
        finalizeJobs.update(userAvailabilityReference(date, user.id), {
          final: true,
        })

        if (driverReference === null) return;

        const curerntUserIsDriver = user.get("isDriver");
        if (curerntUserIsDriver === undefined) return;
        if (curerntUserIsDriver === false) return;
        driverReference = user.id;
    });
    if (driverReference === null) {
      driverReference = currentUser.uid;
    }

    finalizeJobs.set(
      flyingDayReference(date),
      {
        final: true,
        leavingTime: Timestamp.fromDate(leavingDate),
        notes: notes,
        driverId: driverReference,
      },
      {
        merge: true,
      }
    );


    return await finalizeJobs.commit();
  }

  //TODO: move this to displayed list context
  function getUnapprovedUsersList() {
    if (!currentUser) throw new Error("User is not signed in");
    if (!currentUserInfo) throw new Error("User is not in DB");
    if (!currentUserInfo.isAdmin) throw new Error("User is not an admin");
    
    return getUserList(unapprovedUsersListReference());
  }

  async function approveUser(uid: string) {
    if (!currentUser) throw new Error("User is not signed in");
    if (!currentUserInfo) throw new Error("User is not in DB");
    if (!currentUserInfo.isAdmin) throw new Error("User is not an admin");

    const batch = writeBatch(db);

    batch.update(userDocumentReference(uid), {
      approved: true,
      userState: 3,
    });

    batch.delete(doc(unapprovedUsersListReference(), uid));

    await batch.commit();
  }

  async function approvePayments(payments: Array<Payment>) {
    if (!currentUser) throw new Error("User is not signed in");
    if (!currentUserInfo) throw new Error("User is not in DB");
    if (!currentUserInfo.isAdmin) throw new Error("User is not an admin");

    await Promise.all(
      payments.flatMap(payment => [
        updateDoc(
          doc(
            db,
            "payments",
            payment.id,
          ),
          {
            status: PaymentStatus.approved
          }
        ),
        updateDoc(
          doc(
            db,
            "users",
            payment.paidBy.uid,
            "payments",
            payment.id
          ),
          {
            status: PaymentStatus.approved
          }
        ),
        payment.paysOff.flatMap(ref => [
          updateDoc(
            doc(
              db,
              "users",
              payment.paidBy.uid,
              "flyingDays",
              ref
            ),
            {
              status: Status.Paid
            }
          ),
          updateDoc(
            doc(
              db,
              "flyingDays",
              ref
            ),
            {
              status: Status.Paid
            }
          ),
        ])
      ])
    )
    updatePayments();
    updateUserOwes(payments.map(payment => payment.paidBy.uid));
  }

  async function updateUserOwes(uids: Array<string>): Promise<void> {
    uids.forEach(async uid => {
      let userOwes = (await getDocs(
        collection(
          db,
          "users",
          uid,
          "flyingDays"
        )
      ))
      .docs
      .filter(
        flyingDay => (flyingDay.get("status") === Status.Unpaid)
      )
      .reduce(
        (owes, flyingDay) => owes + (flyingDay.get("cost") === undefined ? 0 : flyingDay.get("cost")),
        0
      );

      updateDoc(
        doc(db, "users", uid),
        {
          owes: userOwes
        }
      )
    });
  }

  async function deleteUnpublishedDay(
    date: Date,
    user: SelectableUser,
  ) {
    if (!currentUser) throw new Error("User is not signed in");
    if (!currentUserInfo) throw new Error("User is not in DB");
    if (!currentUserInfo.isAdmin) throw new Error("User is not an admin");

    let userFlyingDay = unpublishedUserFlyingDays.find(
      (userFlyingDay) => userFlyingDay.equals(
        date,
        user.uid,
      )
    );

    if (userFlyingDay === undefined) throw new Error("User flying day is not stored");

    const userFlyingDayDoc = doc(
      db,
      "unpublishedFlyingDays",
      userFlyingDay.getDayReference(),
    );

    const userFlyingDayFlights = collection(userFlyingDayDoc, "flights");
    const userFlyingDayFlightsDocs = await getDocs(userFlyingDayFlights);

    await Promise.all(userFlyingDayFlightsDocs.docs.map(flightDoc => deleteDoc(
      doc(
        db,
        "flights",
        flightDoc.id,
    ))));
    
    await Promise.all(userFlyingDayFlightsDocs.docs.map(flightDoc => deleteDoc(flightDoc.ref)));

    await deleteDoc(doc(
      db,
      "unpublishedFlyingDays",
      userFlyingDay.getDayReference(),
    )).then(() => {
      updateUnpublishedUserFlyingDays();
    });

  }

  async function addFlight(
    date: Date,
    length: number,
    compNumber: string,
    launchType: string,
    launchFailure: string,
    launchLocation: string,
    p1: SelectableUser,
    p2: SelectableUser | undefined,
    freeLaunch: boolean,
  ) {
    if (!currentUser) throw new Error("User is not signed in");
    if (!currentUserInfo) throw new Error("User is not in DB");
    if (!currentUserInfo.isAdmin) throw new Error("User is not an admin");

    let userFlyingDay = unpublishedUserFlyingDays.find(
      (userFlyingDay) => userFlyingDay.equals(
        date,
        p1.uid,
      )
    );

    if (userFlyingDay === undefined)
      userFlyingDay = new UserFlyingDay(
        date,
        p1,
        0,
        0,
        Status.Unpublished,
      );
    else
      userFlyingDay = await getFlights(
        userFlyingDay,
        collection(db, "unpublishedFlyingDays")
      );

    let flight = new Flight(
      undefined,
      date,
      length,
      compNumber,
      launchType,
      launchFailure,
      launchLocation,
      p1,
      p2,
      freeLaunch,
    )

    flight.id = (await addDoc(
      collection(db, "flights")
        .withConverter(flightConverter),
      flight
    )).id

    userFlyingDay.addFlight(flight);

    await addFlyingDayToCollection(
      userFlyingDay,
      collection(db,
        "unpublishedFlyingDays",
      )
    );
  }

  async function publishFlyingDays(flyingDays: Array<UserFlyingDay>) {
    if (!currentUser) throw new Error("User is not signed in");
    if (!currentUserInfo) throw new Error("User is not in DB");
    if (!currentUserInfo.isAdmin) throw new Error("User is not an admin");

    flyingDays.forEach(flyingDay => flyingDay.status = Status.Unpaid);
    await Promise.all(
      flyingDays.flatMap(
        day => [
          addFlyingDayToCollection(day, collection(db, "flyingDays")),
          addFlyingDayToCollection(day, collection(db, "users", day.user.uid, "flyingDays")),
          deleteDoc(doc(
            db,
            "unpublishedFlyingDays",
            day.getDayReference(),
          ))
        ]
      )
    )
    await updateUnpublishedUserFlyingDays();

    // This should filter the list of flying days being published to only include
    // the latest day for each user
    flyingDays.sort((a, b) => a.date.getUTCSeconds() - b.date.getUTCSeconds());
    flyingDays.filter((day, index, currentFlyingDays) => (currentFlyingDays.indexOf(day) === index))

    // I should check that the current last flew date is less than the new one I'm about to set... but I'm not going to cos dealing with edge cases is for pussys
    flyingDays.forEach(flyingDay => {
      updateDoc(
        doc(db, "users", flyingDay.user.uid),
        {
          "lastFlew" : flyingDay.date
        }
      );
    });
    updateUserOwes(flyingDays.map(flyingDay => flyingDay.user.uid));
  }

  async function addFlyingRate(flyingRate: FlyingRate) {
    if (!currentUser) throw new Error("User is not signed in");
    if (!currentUserInfo) throw new Error("User is not in DB");
    if (!currentUserInfo.isAdmin) throw new Error("User is not an admin");

    CostCalculator.addFlyingRate(flyingRate);

    await addDoc(
      collection(db, "flyingRates")
        .withConverter(flyingRateConverter),
      flyingRate
    )
  }

  async function addGlider(glider: Glider) {
    if (!currentUser) throw new Error("User is not signed in");
    if (!currentUserInfo) throw new Error("User is not in DB");
    if (!currentUserInfo.isAdmin) throw new Error("User is not an admin");

    await addDoc(
      collection(db, "gliders")
        .withConverter(gliderConverter),
      glider
    );
  }

  const value = {
    getFlyingListInfo,
    addUserToFlyingList,
    toggleUserDriverStatus,
    removeUserFromFlyingList,
    finalizeFlyingList,

    getUnapprovedUsersList,
    approveUser,

    approvePayments,

    deleteUnpublishedDay,

    addFlight,
    publishFlyingDays,

    addFlyingRate,
    addGlider,
  };

  return <DBContext.Provider value={value}>{children}</DBContext.Provider>;
}
