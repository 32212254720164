import * as React from 'react'
import { useState } from 'react';
import { Form, Card, Modal, Button } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { useApprovedContext } from '../../contexts/ApprovedContext';

export default function ProfileInformation() {
  const { currentUser, currentUserInfo, updateCurrentUserInfo } = useAuth();
  const { setDriver, setMaxPassengerCount, setPhoneNumber } = useApprovedContext();
  const [showDriverModal, setShowDriverModal] = useState(false);

  async function phoneNumberChangedHandler(event: React.ChangeEvent<HTMLInputElement>) {
    const phoneNumber = event.currentTarget.value;
    if (phoneNumber === undefined) return;

    await setPhoneNumber(phoneNumber);
    updateCurrentUserInfo();
    console.log(currentUserInfo)
  }

  async function openDriverModal() {
    //setDriver(!currentUserInfo?.isDriver);
    //updateCurrentUserInfo();
    if (currentUserInfo?.isDriver) {
      setDriver(false);
      updateCurrentUserInfo();
    } else {
      setShowDriverModal(true);
    }
  }

  function closeDriverModalCancel() {
    setShowDriverModal(false);
  }

  function closeDriverModalAccept() {
    setDriver(true);
    updateCurrentUserInfo();
    setShowDriverModal(false);
  }

  async function passengerCountChanged(event: React.ChangeEvent<HTMLInputElement>) {
    const newCount = parseInt(event.currentTarget.value);
    if (newCount === undefined) return;

    await setMaxPassengerCount(newCount);
    updateCurrentUserInfo();
  }

  return (
    <Card style={{ width: "21em" }}>
      <Card.Body>
        <h2 className='text-center mb-4'>Profile Information</h2>
        <Form>
          <Form.Group id="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              defaultValue={currentUser && currentUser.email ? currentUser.email : ""}
              disabled
            />
          </Form.Group>
          <Form.Group id="phone">
            <Form.Label>Phone Number:</Form.Label>
            <Form.Control
              type="tel"
              defaultValue={currentUserInfo && currentUserInfo.phoneNumber ? currentUserInfo.phoneNumber : ""}
              onChange={phoneNumberChangedHandler}
            />
          </Form.Group>
          <Form.Group id="driver" className='pt-3 row'>
            <Form.Check
              checked={currentUserInfo?.isDriver}
              onChange={openDriverModal}
              className='col-1'
            />
            <Form.Label
              onClick={openDriverModal}
              className='col-11'
            >
              Is Driver?
            </Form.Label>
          </Form.Group>
          <Form.Group id="driver" className='pt-3' hidden={!currentUserInfo?.isDriver}>
            <Form.Label>Passenger capacity (excl driver)</Form.Label>
            <Form.Control
              type='number'
              value={currentUserInfo?.passengerCount}
              min={0}
              onChange={passengerCountChanged}
            />
          </Form.Group>
        </Form>
      </Card.Body>

      <Modal show={showDriverModal} onHide={closeDriverModalCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Becoming an SUGC Rideshare Driver</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          By enabling your driver status with SUGC, you confirm:
          <ul>
            <li>I have been briefed on driving on an airfield</li>
            <li>I have a valid UK drivers licence</li>
            <li>I will keep my car insured and within MOT when using it for SUGC</li>
            <li>My car insurance may not cover me while on the airfield</li>
            <li>Even though I have my own transport, I still need to be assigned to a flying list to be permitted to fly at Shalbourne</li>
            <li>I am allowed to ask my passengers for £5 each to cover fuel costs</li>
            <li>My phone number will be given to my passengers when they are assigned to a flying list with me as the driver, so that they can contact me to confirm attendance</li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDriverModalCancel}>
            Cancel
          </Button>
          <Button variant="primary" onClick={closeDriverModalAccept}>
            Accept
          </Button>
        </Modal.Footer>
      </Modal>

    </Card>
  )
}
